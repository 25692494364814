import { combineLatestInit } from '../observable/combineLatest';
import { ObservableInput, ObservableInputTuple, OperatorFunction } from '../types';
import { operate } from '../util/lift';
import { argsOrArgArray } from '../util/argsOrArgArray';
import { mapOneOrManyArgs } from '../util/mapOneOrManyArgs';
import { pipe } from '../util/pipe';
import { popResultSelector } from '../util/args';

/** @deprecated Replaced with {@link combineLatestWith}. Will be removed in v8. */
export function combineLatest<t, A="" extends="" readonly="" unknown[],="" R="">(
  sources: [...ObservableInputTuple<a>],
  project: (...values: [T, ...A]) => R
): OperatorFunction<t, R="">;
/** @deprecated Replaced with {@link combineLatestWith}. Will be removed in v8. */
export function combineLatest<t, A="" extends="" readonly="" unknown[],="" R="">(sources: [...ObservableInputTuple<a>]): OperatorFunction<t, [T,="" ...A]="">;

/** @deprecated Replaced with {@link combineLatestWith}. Will be removed in v8. */
export function combineLatest<t, A="" extends="" readonly="" unknown[],="" R="">(
  ...sourcesAndProject: [...ObservableInputTuple<a>, (...values: [T, ...A]) => R]
): OperatorFunction<t, R="">;
/** @deprecated Replaced with {@link combineLatestWith}. Will be removed in v8. */
export function combineLatest<t, A="" extends="" readonly="" unknown[],="" R="">(...sources: [...ObservableInputTuple<a>]): OperatorFunction<t, [T,="" ...A]="">;

/**
 * @deprecated Replaced with {@link combineLatestWith}. Will be removed in v8.
 */
export function combineLatest<t, R="">(...args: (ObservableInput<any> | ((...values: any[]) => R))[]): OperatorFunction<t, unknown=""> {
  const resultSelector = popResultSelector(args);
  return resultSelector
    ? pipe(combineLatest(...(args as Array<observableinput<any>>)), mapOneOrManyArgs(resultSelector))
    : operate((source, subscriber) => {
        combineLatestInit([source, ...argsOrArgArray(args)])(subscriber);
      });
}
</observableinput<any></t,></any></t,></t,></a></t,></t,></a></t,></t,></a></t,></t,></a></t,>