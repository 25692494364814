import { zip as zipStatic } from '../observable/zip';
import { ObservableInput, ObservableInputTuple, OperatorFunction, Cons } from '../types';
import { operate } from '../util/lift';

/** @deprecated Replaced with {@link zipWith}. Will be removed in v8. */
export function zip<t, A="" extends="" readonly="" unknown[]="">(otherInputs: [...ObservableInputTuple<a>]): OperatorFunction<t, Cons<T,="" A="">>;
/** @deprecated Replaced with {@link zipWith}. Will be removed in v8. */
export function zip<t, A="" extends="" readonly="" unknown[],="" R="">(
  otherInputsAndProject: [...ObservableInputTuple<a>],
  project: (...values: Cons<t, A="">) => R
): OperatorFunction<t, R="">;
/** @deprecated Replaced with {@link zipWith}. Will be removed in v8. */
export function zip<t, A="" extends="" readonly="" unknown[]="">(...otherInputs: [...ObservableInputTuple<a>]): OperatorFunction<t, Cons<T,="" A="">>;
/** @deprecated Replaced with {@link zipWith}. Will be removed in v8. */
export function zip<t, A="" extends="" readonly="" unknown[],="" R="">(
  ...otherInputsAndProject: [...ObservableInputTuple<a>, (...values: Cons<t, A="">) => R]
): OperatorFunction<t, R="">;

/**
 * @deprecated Replaced with {@link zipWith}. Will be removed in v8.
 */
export function zip<t, R="">(...sources: Array<observableinput<any> | ((...values: Array<any>) => R)>): OperatorFunction<t, any=""> {
  return operate((source, subscriber) => {
    zipStatic(source as ObservableInput<any>, ...(sources as Array<observableinput<any>>)).subscribe(subscriber);
  });
}
</observableinput<any></any></t,></any></observableinput<any></t,></t,></t,></a></t,></t,></a></t,></t,></t,></a></t,></t,></a></t,>